import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigurationService } from 'app/common/configuration';
import { BaseService } from 'app/features/employee-management/shared/base.service';

import { ActivityList } from '../models/activitiesList.model';

@Injectable({
    providedIn: 'root',
})
export class PreferredActivityService extends BaseService {
    constructor(httpClient: HttpClient, appConfigurationService: AppConfigurationService) {
        super(httpClient);
        this.setBaseUrl(`${appConfigurationService.configuration.uris?.preferredActivitiesService}`);
    }

    public getAll() {
        return this.getRequest<ActivityList>();
    }

    public getById(employeeId: string) {
        const url = `${this.getBaseUrl()}/${employeeId}`;
        return this.getRequest<ActivityList>(url);
    }

    public updateActivity(employeeId: string, activityList: ActivityList) {
        const url = `${this.getBaseUrl()}/${employeeId}/updatePreferredActivities`;
        return this.putRequest<ActivityList>(url, activityList);
    }

    public removeEmployeePreferredActivity(employeeId: string, activityId: string) {
        const url = `${this.getBaseUrl()}/employee/${employeeId}/activity/${activityId}`;
        return this.deleteRequest<any>(url);
    }

    public preferredActivitiesUnassignedEmployees(
        activityId: string,
        activityName: string,
        employeeIds: string[],
    ) {
        const url = `${this.getBaseUrl()}/employees`;
        return this.postRequest<any>(url, { activityId, activityName, employeeIds });
    }
}
