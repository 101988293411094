import { createReducer, on } from '@ngrx/store';

import { Activity } from 'app/core/activities/models/activity.model';

import { PreferredActivitiesActions } from './preferred.activities.actions';

export interface PreferredActivitiesState {
    loading: boolean;
    preferredActivities: Activity[];
    selectedPreferredActivities?: {
        preferredActivities: Activity[];
        isAvailableForOtherActivities: boolean;
    } | null;
    isAvailableForOtherActivities: boolean;
    isActive: boolean;
    errorMessage: string;
}

const initalState: PreferredActivitiesState = {
    loading: true,
    preferredActivities: [],
    isAvailableForOtherActivities: false,
    selectedPreferredActivities: null,
    isActive: true,
    errorMessage: '',
};

export const preferredActivitiesReducer = createReducer(
    initalState,
    on(PreferredActivitiesActions.loadPreferredActivities, (state) => ({
        ...state,
        loading: true,
        preferredActivities: [],
        isAvailableForOtherActivities: false,
        errorMessage: '',
    })),
    on(PreferredActivitiesActions.loadPreferredActivitiesSuccess, (state, { activities }) => ({
        ...state,
        loading: false,
        preferredActivities: activities.preferredActivities,
        isAvailableForOtherActivities: activities.isAvailableForOtherActivities,
        errorMessage: '',
    })),
    on(PreferredActivitiesActions.loadPreferredActivitiesFailure, (state, { message }) => ({
        ...state,
        preferredActivities: [],
        isAvailableForOtherActivities: false,
        errorMessage: message,
        loading: false,
    })),
);
