import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigurationService } from 'app/common/configuration';
import { SiteFormatBE } from 'app/core/sites/models/site.model';

@Injectable({
    providedIn: 'root',
})
export class SitesService {
    private baseUrl: string = '';

    constructor(
        private httpClient: HttpClient,
        appConfigurationService: AppConfigurationService,
    ) {
        this.baseUrl = `${appConfigurationService.configuration.uris?.siteService}`;
    }

    public getAll() {
        const url = `${this.baseUrl}`;
        return this.httpClient.get<SiteFormatBE[]>(url);
    }
}
