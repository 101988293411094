import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CropData } from 'app/core/roles/models/crop.data.model';

export const CropDataActions = createActionGroup({
    source: 'CropData',
    events: {
        'Load CropData': emptyProps(),
        'Load CropData Success': props<{ cropData: CropData }>(),
        'Load CropData Failure': props<{ message: any }>(),
    },
});
