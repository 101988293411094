import { createReducer, on } from '@ngrx/store';

import { Site } from 'app/core/sites/models/site.model';

import { SitesActions } from './sites.actions';

export interface SitesState {
    loading: boolean;
    sites: Site[];
    errorMessage: string;
}

const initalState: SitesState = {
    loading: true,
    sites: [],
    errorMessage: '',
};

export const sitesReducer = createReducer(
    initalState,
    on(SitesActions.loadSites, (state) => ({
        ...state,
        loading: true,
        sites: [],
        errorMessage: '',
    })),
    on(SitesActions.loadSitesSuccess, (state, { sites }) => ({
        ...state,
        loading: false,
        sites: sites,
    })),
    on(SitesActions.loadSitesFailure, (state, { message }) => ({
        ...state,
        sites: [],
        errorMessage: message,
        loading: false,
    })),
);
