import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Activity } from 'app/core/activities/models/activity.model';
import { RoleFormatBE } from 'app/core/roles/models/role.model';
import { EmployeeInfo } from 'app/features/employee-management/models/employeeInfo.model';

export const ActivitiesActions = createActionGroup({
    source: 'Activities',
    events: {
        'Load Activities': emptyProps(),
        'Load Activities Success': props<{ activities: Activity[] }>(),
        'Load Activities Failure': props<{ message: any }>(),

        'Get Activity By Id': props<{ activityId: string }>(),
        'Load Activities By Id Success': props<{ activity: Activity }>(),
        'Update Activity By Id': props<{ activity: Activity }>(),
        'Update Activity Roles By Id': props<{ activityId: string; roles: string[] }>(),
        'Update Activity Roles By Id Success': props<{ activity: any }>(),

        'Update CropSegment Activity By Id': props<{ activityId: string; segmentId: string | null }>(),
        'Update CropSegment Activity By Id Success': props<{ activity: any }>(),
        'Update CropSegment Activity By Id Failure': props<{ message: any }>(),
        'Reset CropSegment Activity By Id Message': props<{ message: any }>(),

        'Load Employees': props<{ activityId: string }>(),
        'Load Employees By ActivityId Success': props<{ employees: EmployeeInfo[] }>(),
        'Load Employees By ActivityId Failure': props<{ message: any }>(),

        'Remove Employee Activity By Id': props<{ employeeId: string; activityId: string }>(),
        'Remove Employee Activity By Id Success': props<{ employeeId: string }>(),
        'Remove Employee Activity By Id Failure': props<{ message: string }>(),
        'Remove Employee Activity By Id Failure Specific': props<{ message: string }>(),
        'Add Employee To Activity': props<{ employee: EmployeeInfo }>(),
        'Add Unassign Employees To Activity': props<{
            activityId: string;
            activityName: string;
            employeeIds: string[];
        }>(),
        'Add Unassign Employees To Activity Id Success': props<{ activityId: string }>(),
        'Undo Employee Activity By Id': props<{ employeeId: string; activityId: string }>(),

        'Load Unassigned Employees': props<{ activityId: string; selectedRoles?: RoleFormatBE[] }>(),
        'Load Unassigned Employees By ActivityId Success': props<{ employees: EmployeeInfo[] }>(),
        'Load Unassigned Employees By ActivityId Failure': props<{ message: any }>(),
    },
});
